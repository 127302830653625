/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~leaflet/dist/leaflet.css";

@import "~leaflet.locatecontrol/dist/L.Control.Locate.css";
/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */
 
 @import '~swiper/scss';
 @import '~@ionic/angular/css/ionic-swiper';
 @import '~swiper/scss/pagination';

//  IcoMoon
 @import 'assets/fonts/icomoon/style.css';

 .lexend{
  font-family: 'Lexend' !important;
}

ion-label{
  font-family: 'Lexend' !important;
}

ion-grid, ion-text{
  font-family: 'Lexend' !important;
}

 .icons{
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .rotate180{
   transform: rotateX(180deg);
 }
/* Loading Spinner */
.loader-spinner {
  z-index: 99000 !important;
}
 
/* placed here instead of intro.scss due to slide els not getting ng scoping attribute */
 .swiper-slide {
   display: block;
 }

 /*Modals*/

 .sub-wallet-modal{
    position: absolute;
    --width: 277px !important;
    --height: 249px !important;
    --border-radius: 20px;
    --overflow: hidden !important;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.confirm-delete-modal{
  position: absolute;
  --max-width: 301px !important;
  --max-height: 303px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.move-success-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 352px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
.connection-modal{
  position: absolute;
  --max-width: 246px !important;
  --max-height: 235px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.confirm-send-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 339px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.confirm-convert-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 339px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.convert-success-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 389px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.convert-error-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 329px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.send-success-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 389px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.send-error-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 329px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.qr-success-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 389px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.login-error-modal{
  position: absolute;
  --max-width: 277px !important;
  --min-height: 339px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.upgrade-modal{
  position: absolute;
  --max-width: 329px !important;
  --max-height: 630px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.datetimepicker-modal{
  position: absolute;
  --max-width: 295px !important;
  --max-height: 350px !important;    
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.dashboard-qr-modal{
  position: absolute;
  --width: 270px !important;
  --height: 380px!important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.choose-img-source-modal{
  position: absolute;
  --width: 100% !important;
  --height: 150px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.check-upgrade-modal{
  position: absolute;
  --width: 277px !important;
  --height: 257px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.notification{
  --background:#FFAA2B;
}

@media screen and (max-width: 370px) {
  .upgrade-modal{
    --max-width: 275px !important;
    --max-height: 530px !important; 
    --padding: 2rem;   
    --overflow: hidden !important;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  }
}

.scan-failed-modal{
  position: absolute;
  --max-width: 282px !important;
  --max-height: 306px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.scan-success-modal{
  position: absolute;
  --max-width: 282px !important;
  --max-height: 306px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.withdraw-request-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 287px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.withdraw-failed-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 329px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.filter-transaction-modal{
  position: absolute;
  --min-width: 100% !important;
  --max-height: 470px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 30px;
}

.upgrade-failed-modal{
  position: absolute;
  --max-width: 314px !important;
  --max-height: 525px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  // --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 15px;
  padding: 37px !important;
}

.kyc-submit-modal{
  position: absolute;
  --max-width: 308px !important;
  --max-height: 330.3px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 40px;
  padding: 37px !important;
}

.confirm-cashin-modal{
  position: absolute;
  bottom: 0px;
  --width: 317px !important;
  --max-height: 317px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 0px !important;
}

.rate-change-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 284px !important;
  --overflow: hidden !important;
  --box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
  --border-radius: 20px;
  padding: 37px !important;
}

.cashin-md-modal{
  position: absolute;
  --max-width: 317px !important;
  --max-height: 418px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

.cashout-via-modal{
  position: absolute;
  --min-width: 100% !important;
  --max-height: 393px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

.select-wallet-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 100% !important;
  --max-height: 393px !important;
  --overflow: hidden !important;
  --border-radius: 10px;
  padding: 22px !important;
}

.upgrade-required-modal{
  --width: 295px !important;
  --height: 340px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
}

.password-confirm-modal {
  position: absolute;
  bottom: 0px;
  --max-width: 317px !important;
  --max-height: 309px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

.delete-account-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 317px !important;
  --max-height: 370px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

.settings-changed-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 317px !important;
  --max-height: 278px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

.delete-account-success-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 307px !important;
  --max-height: 179px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

.unable-delete-account-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 317px !important;
  --max-height: 257px  !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

.login-bio-modal{
  position: absolute;
  bottom: 0px;
  --width: 100%;
  --height: 311px !important;
  --overflow: hidden !important;
  --border-radius: 0px !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  padding: 22px !important;
}

ion-modal.confirm-unlink{
  position: absolute;
  --max-width: 277px !important;
  --min-height: 324px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.save-qr-success{
  position: absolute;
  bottom: calc(95% - 45px);
  --max-width: 230px !important;
  --height: 32px !important;
  --overflow: hidden !important;
  // --box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25) !important;
  --backdrop-opacity: 0 !important;
  --border-radius: 24px !important;

  &::part(content) {
    border: 1px solid #0D7B63;
  }
}

ion-modal.delete-announcement-success{
  position: absolute;
  bottom: calc(95% - 45px);
  --max-width: 200px !important;
  --height: 32px !important;
  --overflow: hidden !important;
  // --box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25) !important;
  --backdrop-opacity: 0 !important;
  --border-radius: 24px !important;

  &::part(content) {
    border: 1px solid #EC4B4B;
  }
}

ion-modal.edit-announcement-success{
  position: absolute;
  bottom: calc(95% - 45px);
  --max-width: 200px !important;
  --height: 32px !important;
  --overflow: hidden !important;
  // --box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25) !important;
  --backdrop-opacity: 0 !important;
  --border-radius: 24px !important;

  &::part(content) {
    border: 1px solid #147AF7;
  }
}

ion-modal.community-type-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 100% !important;
  --max-height: 438px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

ion-modal.corporation-nationality-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 100% !important;
  --max-height: 200px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

ion-modal.community-register-success-modal{
  position: absolute;
  --max-width: 277px !important;
  --min-height: 380px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.store-register-success-modal{
  position: absolute;
  --max-width: 277px !important;
  --min-height: 380px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.delete-success-modal{
  position: absolute;
  --width: 307px;
  --height: 161px !important;
  --overflow: hidden !important;
  --border-radius: 10px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

.ad-confirm-cancel-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 315px !important;
  --max-height: 275px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 28px;
  padding: 22px !important;
}

.select-ad-type-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 100% !important;
  --max-height: 238px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

.confirm-password-modal {
  position: absolute;
  bottom: 0px;
  --width: 317px !important;
  --max-height: 309px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  --padding: 22px !important;
}

.biometric-pin-success-modal {
  position: absolute;
  bottom: 0px;
  --max-width: 317px !important;
  --max-height: 217px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  padding: 22px !important;
}

.p2p-buy-cancel-modal {
  position: absolute;
  bottom: 0px;
  --width: 269px !important;
  --height: 260px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 28px;
  padding: 22px !important;
}

.p2p-buy-failed-modal {
  position: absolute;
  bottom: 0px;
  --width: 269px !important;
  --height: 300px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 28px;
  padding: 22px !important;
}

.session-timeout-modal {
  position: absolute;
  bottom: 0px;
  --width: 300px !important;
  --height: 148px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
  // padding: 22px !important;
}

.failed-password-modal {
  position: absolute;
  bottom: 0px;
  --width: 277px !important;
  --height: 327px !important;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 20px;
}

.account-disabled-modal {
  position: absolute;
  bottom: 0px;
  --width: 317px !important;
  --height: 354px !important;
  --overflow: hidden !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
}

.login-validation-modal {
  position: absolute;
  bottom: 0px;
  --width: 317px !important;
  --height: 325px !important;
  --overflow: hidden !important;
  // --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
}

.login-deleted-modal {
  position: absolute;
  bottom: 0px;
  --width: 317px !important;
  --height: 307px !important;
  --overflow: hidden !important;
  // --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 10px;
}

.show-promo-details-modal {
  position: absolute;
  bottom: 0px;
  --width: 277px !important;
  --height: 216px !important;
  --overflow: hidden !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  --border-radius: 21px;
}

ion-modal.cancel-changes-confirm-modal{
  position: absolute;
  --max-width: 277px !important;
  --max-height: 431px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.refer-link-success-modal{
  position: absolute;
  --width: 307px;
  --height: 300px !important;
  --overflow: hidden !important;
  --border-radius: 10px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

ion-modal.bill-status-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 100% !important;
  --max-height: 221.51px !important;
  --overflow: hidden !important;
  --border-radius: 21px;
  padding: 22px !important;
}

ion-modal.confirm-join-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 290px !important;
  --max-height: 285px !important;
  --overflow: hidden !important;
  --border-radius: 21px;
  padding: 22px !important;
}

ion-modal.join-success-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 290px !important;
  --max-height: 285px !important;
  --overflow: hidden !important;
  --border-radius: 21px;
  padding: 22px !important;
}

ion-modal.register-community-success-modal{
  position: absolute;
  --max-width: 280px !important;
  --min-height: 240px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.register-store-success-modal{
  position: absolute;
  --max-width: 280px !important;
  --min-height: 240px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.delete-announcement-confirm-modal{
  position: absolute;
  --max-width: 277px !important;
  --min-height: 275px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.edit-announcement-confirm-modal{
  position: absolute;
  --max-width: 295px !important;
  --min-height: 275px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.create-announcement-success-modal{
  position: absolute;
  --max-width: 280px !important;
  --min-height: 275px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-modal.coming-soon-modal{
  position: absolute;
  --max-width: 280px !important;
  --min-height: 309px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --height: calc(var(--min-height) + 1rem );  
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.transaction-confirm-modal{
  position: absolute;
  bottom: 0px;
  --border-radius: 0px !important;
  --min-width: 100% !important;
  --max-height: 420px !important;
  --overflow: hidden !important;
  padding: 22px !important;
}
.web-transaction-confirm-modal{
  position: absolute;
  bottom: 0px;
  --max-width: 407px !important;
  --max-height: 420px !important;
  --overflow: hidden !important;
  padding: 22px !important;
  --border-radius: 21px;
}

.payment-confirm-modal{
  position: absolute;
  --max-width: 300px !important;
  --border-radius: 21px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
        max-height: 80vh;
        overflow: auto;
        padding: 10px;
    }
  }
}

.map-modal{
  position: absolute;
  --max-width: 450px !important;
  --min-height: 90% !important;
  --border-radius: 10px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.error-modal{
  position: absolute;
  --max-width: 277px !important;
  --border-radius: 20px;
  --overflow: hidden !important;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
        max-height: 80vh;
        overflow: auto;
        padding: 10px;
    }
  }
}


@media screen and (max-width: 320px) {
  .confirm-password-modal {
    --height: 340px !important;
    --width: 285px !important;
  }

  .account-disabled-modal {
    --width: 310px !important;
    --height: 354px !important;
  }

  .login-validation-modal {
    --width: 310px !important;
    --height: 325px !important;
  }

  .login-deleted-modal {
    --width: 310px !important;
    --height: 307px !important;
  }
}
// .code-verification-modal{
//   position: absolute;
//   bottom: 0px;
//   --max-width: 317px !important;
//   --max-height: 278px !important;
//   --overflow: hidden !important;
//   --box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
//   --border-radius: 10px;
//   padding: 22px !important;
// }

// .cashin-receipt-modal, 
// .cashin-receipt-da5-modal,
// .cashin-receipt-merchant-modal,
// .cashout-lg-modal,
// .cashout-receipt-modal,
// .cashout-receipt-ewallet-modal,
// .cashout-receipt-tollway-modal,
// .cashout-receipt-remittance-modal,
// .bills-payment-receipt-modal,
// .e-load-receipt-modal,
// .code-verification-modal{
//   // position: absolute;
//   // --max-width: 25rem !important;
//   // --max-height: 100% !important;
//   // --overflow: hidden !important;
//   // --box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
//   // --border-radius: 30px;
//   // padding: 22px !important;
// }

  /* Font size */
.t-fs70-36{
  font-size: 70.36px;
}
.t-fs60{
  font-size: 60px;
}
.t-fs50{
  font-size: 50px;
}
.t-fs48{
  font-size: 48px !important;
}
.t-fs45-28{
  font-size: 45.28px;
}
.t-fs43-4{
  font-size: 43.4px;
}
.t-fs44{
  font-size: 40px;
}
.t-fs40{
  font-size: 40px;
}
.t-fs27-68{
  font-size: 27.68px;
}
.t-fs38-43{
  font-size: 38.43px;
}
.t-fs36{
  font-size: 36px;
}
.t-fs34-67{
  font-size: 34.67px;
}
.t-fs32{
  font-size: 32px;
}
.t-fs30{
  font-size: 30px;
}
.t-fs34-44{
  font-size: 34.44px;
}
.t-fs34-29{
  font-size: 34.29px;
}
.t-fs26{
  font-size: 26px;
}
.t-fs24{
  font-size: 24px;
}
.t-fs23{
  font-size: 23px;
}
.t-fs22{
  font-size: 22px;
}
.t-fs22-36{
  font-size: 22.36px;
}
.t-fs20-83{
  font-size: 20.83px;
}
.t-fs21{
  font-size: 21px;
}
.t-fs20{
  font-size: 20px;
}
.t-fs19-82{
  font-size: 19.82px;
}
.t-fs18{
  font-size: 18px;
}
.t-fs17-12{
  font-size: 17.12px;
}
.t-fs16-76{
  font-size: 16.76px;
}
.t-fs16{
  font-size: 16px !important;
}
.t-fs15{
  font-size: 15px;
}
.t-fs14-98{
  font-size: 14.98px;
}
.t-fs14-91{
  font-size: 14.91;
}
.t-fs14-93{
  font-size: 14.93px;
}
.t-fs14-69{
  font-size: 14.69px;
}
.t-fs14-09{
  font-size: 14.09px;
}
.t-fs14{
  font-size: 14px !important;
}
.t-fs13-89{
  font-size: 13.89px;
}
.t-fs13{
  font-size: 13px;
}
.t-fs12-79{
  font-size: 12.79px;
}
.t-fs12-64{
  font-size: 12.64px;
}
.t-fs12-62{
  font-size: 12.62px;
}
.t-fs12-43{
  font-size: 12.43px;
}
.t-fs12-41{
  font-size: 12.41px;
}
.t-fs12-26{
  font-size: 12.26px;
}
.t-fs12-15{
  font-size: 12.15px;
}
.t-fs12-06{
  font-size: 12.06px;
}
.t-fs12{
  font-size: 12px !important;
}
.t-fs10-91{
  font-size: 10.91px;
}
.t-fs10-69{
  font-size: 10.69px;
}
.t-fs10-51{
  font-size: 10.51px;
}
.t-fs10{
  font-size: 10px !important;
}
.t-fs11-77{
  font-size: 11.77px;
}
.t-fs11-61{
  font-size: 11.61px;
}
.t-fs11-38{
  font-size: 11.38px;
}
.t-fs11-28{
  font-size: 11.28px;
}
.t-fs11-8{
  font-size: 11.8px;
}
.t-fs11-07{
  font-size: 11.07px;
}
.t-fs11-4{
  font-size: 11.4px;
}
.t-fs11{
  font-size: 11px;
}
.t-fs10-87{
  font-size: 10.87px;
}
.t-fs10-83{
  font-size: 10.83px;
}
.t-fs10-77{
  font-size: 10.77px;
}
.t-fs10-07{
  font-size: 10.07px;
}
.t-fs10{
  font-size: 10px;
}
.t-fs9-77{
  font-size: 9.77px;
}
.t-fs9-55{
  font-size: 9.55px;
}
.t-fs9-48{
  font-size: 9.48px;
}
.t-fs9-29{
  font-size: 9.29px;
}
.t-fs9-27{
  font-size: 9.27px;
}
.t-fs9-3{
  font-size: 9.3px;
}
.t-fs9{
  font-size: 9px;
}
.t-fs9-19{
  font-size: 9.19px;
}
.t-fs9-13{
  font-size: 9.13px;
}
.t-fs8-77{
  font-size: 8.77px;
}
.t-fs8-38{
  font-size: 8.38px;
}
.t-fs8-29{
  font-size: 8.29px;
}
.t-fs8{
  font-size: 8px;
}
.t-fs7-67{
  font-size: 7.67px;
}
.t-fs7-82{
  font-size: 7.82px;
}
.t-fs7{
  font-size: 7px;
}

 /* Line Height */
.t-lh89-83{
  line-height: 89.83px;
}
.t-lh81-71{
  line-height: 81.71px;
}
 .t-lh68-09{
  line-height: 68.09px;
}
.t-lh65-37{
  line-height: 65.37px;
}
.t-lh61-67{
  line-height: 61.67px;
}
.t-lh59-1{
  line-height: 59.1px;
}
.t-lh56-18{
  line-height: 56.18px;
}
.t-lh52{
  line-height: 52px;
}
.t-lh52-33{
  line-height: 52.33px;
}
.t-lh54-47{
  line-height: 54.47px;
}
.t-lh47{
  line-height: 47px;
}
.t-lh40{
  line-height: 40px;
}
.t-lh37-7{
  line-height: 37.7px;
}
.t-lh35-41{
  line-height: 35.41px;
}
.t-lh33{
  line-height: 33px;
}
.t-lh32-68{
  line-height: 32.68px;
}
.t-lh30-46{
  line-height: 30.46px;
}
.t-lh31-32{
  line-height: 31.32px;
}
.t-lh30-72{
  line-height: 30.72px;
}
.t-lh29-96{
  line-height: 29.96px;
}
.t-lh28-96{
  line-height: 28.96px;
}
.t-lh28-6{
  line-height: 28.6px;
}
.t-lh28-37{
  line-height: 28.37px;
}
.t-lh27-24{
  line-height: 27.24px;
}
.t-lh25-2{
  line-height: 25.2px;
}
.t-lh24-51{
  line-height: 24.51px;
}
.t-lh23-32{
  line-height: 23.32px;
}
.t-lh24{
  line-height: 24px;
}
.t-lh22-83{
  line-height: 22.83px;
}
.t-lh26-99{
  line-height: 26.99px;
}
.t-lh21-79{
  line-height: 21.79px;
}
.t-lh21-72{
  line-height: 21.72px;
}
.t-lh20-43{
  line-height: 20.43px;
}
.t-lh20-3{
  line-height: 20.3px;
}
.t-lh20-34{
  line-height: 20.34px;
}
.t-lh20{
  line-height: 20.34px;
}
.t-lh19-31{
  line-height: 19.31px;
}
.t-lh19-19{
  line-height: 19.19px;
}
.t-lh19-13{
  line-height: 19.13px;
}
.t-lh19-07{
  line-height: 19.07px;
}
.t-lh18-95{
  line-height: 18.95px;
}
.t-lh18-91{
  line-height: 18.91px;
}
.t-lh18-05{
  line-height: 18.05px;
}
.t-lh17-42{
  line-height: 17.42px;
}
.t-lh17-7{
  line-height: 17.7px;
}
.t-lh17-08{
  line-height: 17.08px;
}
.t-lh16-55{
  line-height: 16.55px;
}
.t-lh16-43{
  line-height: 16.43px;
}
.t-lh16-36{
  line-height: 16.36px;
}
.t-lh16-34{
  line-height: 16.34px;
}
.t-lh16-9{
  line-height: 16.9px;
}
.t-lh16-07{
  line-height: 16.07px;
}
.t-lh16-03{
  line-height: 16.03px;
}
.t-lh15-82{
  line-height: 15.82px;
}
.t-lh15-76{
  line-height: 15.76px;
}
.t-lh15-52{
  line-height: 15.52px;
}
.t-lh15-37{
  line-height: 15.37px;
}
.t-lh15-07{
  line-height: 15.07px;
}
.t-lh15-4{
  line-height: 15.4px;
}
.t-lh15-1{
  line-height: 15.1px;
}
.t-lh15{
  line-height: 15px;
}
.t-lh14-98{
  line-height: 14.98px;
}
.t-lh14-67{
  line-height: 14.67px;
}
.t-lh14-66{
  line-height: 14.66px;
}
.t-lh14-56{
  line-height: 14.56px;
}
.t-lh14{
  line-height: 14px;
}
.t-lh13-62{
  line-height: 13.62px;
}
.t-lh13{
  line-height: 13px;
}
.t-lh13-15{
  line-height: 13.15px;
}
.t-lh12-91{
  line-height: 12.91px;
}
.t-lh12-66{
  line-height: 12.66px;
}
.t-lh12{
  line-height: 12px;
}
.t-lh10-89{
  line-height: 10.89px;
}
.t-lh10-87{
  line-height: 10.87px;
}
.t-lh10{
  line-height: 10px;
}
.t-lh11-94{
  line-height: 11.94px;
}
.t-lh11-41{
  line-height: 11.41px;
}
.t-lh11-15{
  line-height: 11.15px;
}
.t-lh11{
  line-height: 11px;
}
.t-lh9-53{
  line-height: 9.53px;
}


.h100{
  height: 100%;
}
.tr{
  text-align: right;
}



/* Font Weight */
.t-fwb{
  font-weight: bold;
}
.t-fw700{
  font-weight: 700;
}
.t-fw600{
  font-weight: 600;
}
.t-fw500{
  font-weight: 500;
}
.t-fw400{
  font-weight: 400;
}
.t-fw300{
  font-weight: 300;
}

.pointer{
  cursor: pointer;
}

/* Images */
.landing-bg{
  background-repeat: no-repeat;    
}
.holding-phone{
   background-repeat: no-repeat;    
}
.disable-img {
    opacity: 0.4;
    filter: alpha(opacity=40); /* msie */
    pointer-events: none;
}

/* Color */
.green-landing{
  color: var(--ion-color-landing-green) !important;
}
.blue-landing{
 color: var(--ion-color-landing-blue) !important;
}

.blue-color{
  color: var(--ion-color-primary) !important;
}
.red-color{
  color: var(--ion-color-danger) !important;
}

/* Flex */
.absolute{
  position: absolute;
}

.column{
  display: flex;
  flex-direction: column;
}
.row{
  display: flex;
  flex-direction: row;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .flex-start{
  display: flex;
  justify-content: flex-start;
 }
 .flex-end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
 }

 .space-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
 }
.align-item-center{
  display: flex;
  align-items: center;
 }
 .align-item-start{
  display: flex;
  align-items: flex-start;
 }

 /* Circle Button */
 .warning-circle{
   position: absolute;
   right: 1rem;
   z-index: 3;    
   background: red;
   border-radius: 999px;
 
   ion-icon{
     font-size: 1rem;
     display: block;
     color: #fff;
     padding: 3px;
   }
 }
/* Margin */
.m0{
  margin: 0;
}
.p0{
  padding: 0;
}
.plr1{
  padding: 0 1rem;
}
.plr2{
  padding: 0 2rem;
}

.r1{
  right: 1rem;
}

.no-margin{
  margin: 0px;
}

.mAuto{
  margin: auto;
}
.mtb1{
 margin: 1rem 0;
}
.mb-0{
  margin-bottom: 0px;
 }
.mb1{
  margin-bottom: 1rem;
 }
 .mb10{
  margin-bottom: 10px;
 }
 .mb2{
  margin-bottom: 20px;
 }
 .mb-02{
  margin-bottom: 2px;
 }
 .mt0{
   margin-top: 0;
  }
.mt0-4{
  margin-top: 0.4rem;
 }
 .mt0-5{
  margin-top: 0.5rem;
 }
.mt1{
  margin-top: 1rem;
 }
 .mt-5{
  margin-top: 5px;
 }
 .mt-20{
  margin-top: 20px;
 }
 .mt-10{
  margin-top: 10px;
 }
 .mt1-5{
  margin-top: 1.5rem;
 }
.mt2{
  margin-top: 2rem;
 }
 .mt20{
  margin-top: 20px;
 }
 .mt3{
  margin-top: 3rem;
 }
 .mt3-5{
  margin-top: 3.5rem;
 }
 .mt4{
  margin-top: 5rem;
 }
.mt5{
 margin-top: 5rem;
}
.ml2{
  margin-left: 2rem;
}
.ml-5{
  margin-left: 5px;
}
.ml-10{
  margin-left: 10px;
}
.mr-5{
  margin-right: 5px;
}
.mr-10{
  margin-right: 10px;
}
.mt-22{
  margin-top: 22px !important;
}
.mt-11{
  margin-top: 11px !important;
}
.mt13{
  margin-top: 13px !important;
}
.tj{
  text-align: justify;
}

.mt-0{
  margin-top: 0px !important;
}

.mb-8{
  margin-bottom: 8px;
}

.mr10{
  margin-right: 10px;
}

.ml5{
  margin-left: 5px;
}

.mt0{
  margin: 0px;
}

.p0{
  padding: 0px !important;
}

.pt-10{
  padding-top: 10px !important;
}

/* width */
.w100{
  width: 100%;
}
.checkbox-square{
  --border-radius: 0%!important
}

/* Error Message */
.error{
  margin-top: 0.7rem;
  small{
      color: red;
      font-weight: 700;
      font-size: 12px;
  }    
}
/* Positions */
.relative{
  position: relative;
  z-index: 99 !important;
}


/* Default Button */
ion-button{    
 --border-radius: 30px;
 --font-family: 'Open Sans';
 --font-style: normal;
 --font-weight: 600;
 --font-size: 12px;
 --text-align: center;
 --padding: 1rem;
}
.pointer{
  cursor: pointer;
}

// Camera Preview
.custom-ion-content {
  background: none !important;
  height: 100%;
}

.capitalize{
  text-transform: capitalize;
}

// Html5Qrcode
.reader-invisible {
  opacity: 0 !important; 
  display: none;
}

.text-clamp{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

ion-popover {
  --background: var(--ion-color-light-blue) !important;
  margin-top: -20px;
  --width: 300px;
  --border-radius: 50%;
}

ion-popover ion-content {
  --background: var(--ion-color-light-blue) !important;
}

.break-word-normal{
  word-break: normal;
}

.break-word-all{
  word-break: break-all;
}

ion-content {
  --margin-top: constant(safe-area-inset-top) !important;
  --margin-top: env(safe-area-inset-top) !important;
}

ion-tab-bar.safe-area {
  padding-bottom: env(safe-area-inset-top) !important;
}

.italic{
  font-style: italic;
}

.d-flex{
  display: flex !important;
}

.justify-content-center{
  justify-content: center !important;
}

.justify-content-end{
  justify-content: flex-end !important;
}

.justify-content-flex-start{
  justify-content: flex-start !important;
}

.justify-content-flex-end{
  justify-content: flex-end !important;
}

.justify-content-between{
  justify-content: space-between !important;
}

.align-items-center{
  align-items: center !important;
}

.align-items-start{
  align-items: flex-start !important;
}

.align-items-end{
  align-items: flex-end !important;
}

.text-clamp-1{
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}

.text-clamp-2{
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
}

ion-grid{
  // background: linear-gradient(180deg, #F8FDFF 2.78%, #0BC7F8 185.67%) !important;
  background: linear-gradient(180deg, #F8FDFF 50.78%, #0BC7F8 196.67%) !important;
  img.bg-image{
      position: fixed;
      bottom: 26px;
      max-width: 40rem;
  }

  @media screen and (max-width: 968px) {
    img.bg-image{
      position: fixed;
      bottom: 43px !important;
      max-width: 40rem;
    }
  }
}