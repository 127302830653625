/*
 * Ionic Variables and Theming
 * ----------------------------------------------------------------------------
 * For more information, please see
 * https://www.ionicframework.com/docs/theming/
 */

 /* Set variables for all modes */
:root {
  /* Set the font family of the entire app */
  --ion-font-family: 'Open Sans';
}

/*
 * Ionic Colors
 * ----------------------------------------------------------------------------
 * Named colors make it easy to reuse colors on various components.
 * It's highly recommended to change the default colors
 * to match your app's branding. Ionic provides nine layered colors
 * that can be changed to theme an app. Additional colors can be
 * added as well (see below). For more information, please see
 * https://www.ionicframework.com/docs/theming/colors
 *
 * To easily create custom color palettes for your app’s UI,
 * check out our color generator:
 * https://www.ionicframework.com/docs/theming/color-generator
 */

 :root {
  /** primary **/
	--ion-color-primary: #3A75F3;
	--ion-color-primary-rgb: 58,117,243;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #3367d6;
	--ion-color-primary-tint: #4e83f4;

  /** secondary **/
  --ion-color-secondary: #0356b4;
	--ion-color-secondary-rgb: 59,132,190;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #3474a7;
	--ion-color-secondary-tint: #4f90c5;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** landing colors **/
  --ion-color-landing-blue:  #6FE9E7;
	--ion-color-landing-rgb-blue: 111,233,231;

  --ion-color-landing-green: #BDEC9C;
	--ion-color-landing-rgb-green: 189,236,156;

  --ion-text-color-blue: #147AF7;
}


/*
 * Additional Ionic Colors
 * ----------------------------------------------------------------------------
 * In order to add colors to be used within Ionic components,
 * the color should be added as a class with the convention `.ion-color-{COLOR}`
 * where `{COLOR}` is the color to be used on the Ionic component.
 * For more information on adding new colors, please see
 * https://ionicframework.com/docs/theming/colors#adding-colors
 *
 * To generate the code for a new color, check out our new color creator:
 * https://ionicframework.com/docs/theming/colors#new-color-creator
 */

 :root {

  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105,187,123;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255,255,255;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;

  --ion-color-twitter: #1da1f4;
  --ion-color-twitter-rgb: 29,161,244;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255,255,255;
  --ion-color-twitter-shade: #1a8ed7;
  --ion-color-twitter-tint: #34aaf5;

  --ion-color-instagram: #5956d8;
  --ion-color-instagram-rgb: 89,86,216;
  --ion-color-instagram-contrast: #ffffff;
  --ion-color-instagram-contrast-rgb: 255,255,255;
  --ion-color-instagram-shade: #4e4cbe;
  --ion-color-instagram-tint: #6a67dc;

  --ion-color-vimeo: #23b6ea;
  --ion-color-vimeo-rgb: 35,182,234;
  --ion-color-vimeo-contrast: #ffffff;
  --ion-color-vimeo-contrast-rgb: 255,255,255;
  --ion-color-vimeo-shade: #1fa0ce;
  --ion-color-vimeo-tint: #39bdec;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59,89,152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,255,255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  /** dark blue **/
  --ion-color-darkblue: #1E549C;
	--ion-color-darkblue-rgb: 30,84,156;
	--ion-color-darkblue-contrast: #ffffff;
	--ion-color-darkblue-contrast-rgb: 255,255,255;
	--ion-color-darkblue-shade: #1a4a89;
	--ion-color-darkblue-tint: #3565a6;

  /** gray **/
  --ion-color-gray: #565656;
	--ion-color-gray-rgb: 86,86,86;
	--ion-color-gray-contrast: #ffffff;
	--ion-color-gray-contrast-rgb: 255,255,255;
	--ion-color-gray-shade: #4c4c4c;
	--ion-color-gray-tint: #676767;

  /* Deep BLue */
  --ion-color-deep-blue: #0F83BB;
	--ion-color-deep-blue-rgb: 15,131,187;
	--ion-color-deep-blue-contrast: #ffffff;
	--ion-color-deep-blue-contrast-rgb: 255,255,255;
	--ion-color-deep-blue-shade: #0d73a5;
	--ion-color-deep-blue-tint: #278fc2;

    /* Light BLue */
  --ion-color-light-blue: #B9D7FF;
  --ion-color-light-blue-rgb: 185,215,255;
  --ion-color-light-blue-contrast: #000000;
  --ion-color-light-blue-contrast-rgb: 0,0,0;
  --ion-color-light-blue-shade: #a3bde0;
  --ion-color-light-blue-tint: #c0dbff;

   /* Light Gray */
  --ion-color-light-gray: #EBEBEB;
  --ion-color-light-gray-rgb: 235,235,235;
  --ion-color-light-gray-contrast: #000000;
  --ion-color-light-gray-contrast-rgb: 0,0,0;
  --ion-color-light-gray-shade: #cfcfcf;
  --ion-color-light-gray-tint: #ededed;

   /* abyan Gray */
  --ion-color-abyan-gray: #A7A7A7;
	--ion-color-abyan-gray-rgb: 167,167,167;
	--ion-color-abyan-gray-contrast: #000000;
	--ion-color-abyan-gray-contrast-rgb: 0,0,0;
	--ion-color-abyan-gray-shade: #939393;
	--ion-color-abyan-gray-tint: #b0b0b0;

  /*Dark Blue*/
  --ion-color-dark-blue: #1E549C;
	--ion-color-dark-blue-rgb: 30,84,156;
	--ion-color-dark-blue-contrast: #ffffff;
	--ion-color-dark-blue-contrast-rgb: 255,255,255;
	--ion-color-dark-blue-shade: #1a4a89;
	--ion-color-dark-blue-tint: #3565a6;

  /*Merchant Green*/
  --ion-color-merchant-green: #0D7B63;
	--ion-color-merchant-green-rgb: 13,123,99;
	--ion-color-merchant-green-contrast: #ffffff;
	--ion-color-merchant-green-contrast-rgb: 255,255,255;
	--ion-color-merchant-green-shade: #0b6c57;
	--ion-color-merchant-green-tint: #258873;

  /*Header Blue*/
  --ion-color-header-blue: #1681f8;
	--ion-color-header-blue-rgb: 22,129,248;
	--ion-color-header-blue-contrast: #ffffff;
	--ion-color-header-blue-contrast-rgb: 255,255,255;
	--ion-color-header-blue-shade: #1372da;
	--ion-color-header-blue-tint: #2d8ef9;

  /*Ash Gray*/

  --ion-color-ash-gray: #ececec;
	--ion-color-ash-gray-rgb: 236,236,236;
	--ion-color-ash-gray-contrast: #000000;
	--ion-color-ash-gray-contrast-rgb: 0,0,0;
	--ion-color-ash-gray-shade: #d0d0d0;
	--ion-color-ash-gray-tint: #eeeeee;

    /*Yellow Green*/
  --ion-color-yellow-green: #4AB65B;
	--ion-color-yellow-green-rgb: 74,182,91;
	--ion-color-yellow-green-contrast: #000000;
	--ion-color-yellow-green-contrast-rgb: 0,0,0;
	--ion-color-yellow-green-shade: #41a050;
	--ion-color-yellow-green-tint: #5cbd6b;

  --ion-color-gradient: linear-gradient(270deg, #4AB65B -22.22%, #0052B7 96.67%);
	--ion-color-gradient-rgb: 59,132,190;
	--ion-color-gradient-contrast: #ffffff;
	--ion-color-gradient-contrast-rgb: 255,255,255;
	--ion-color-gradient-shade: #3474a7;
	--ion-color-gradient-tint: #4f90c5;

  --ion-color-merchant-green: #0D7B63;
	--ion-color-merchant-green-rgb: 13,123,99;
	--ion-color-merchant-green-contrast: #ffffff;
	--ion-color-merchant-green-contrast-rgb: 255,255,255;
	--ion-color-merchant-green-shade: #0b6c57;
	--ion-color-merchant-green-tint: #258873;

  --ion-color-heavy-blue: #0052B7;
	--ion-color-heavy-blue-rgb: 0,82,183;
	--ion-color-heavy-blue-contrast: #ffffff;
	--ion-color-heavy-blue-contrast-rgb: 255,255,255;
	--ion-color-heavy-blue-shade: #0048a1;
	--ion-color-heavy-blue-tint: #1a63be;

  --ion-color-gray-blue: #6B7C95;
	--ion-color-gray-blue-rgb: 107,124,149;
	--ion-color-gray-blue-contrast: #ffffff;
	--ion-color-gray-blue-contrast-rgb: 255,255,255;
	--ion-color-gray-blue-shade: #5e6d83;
	--ion-color-gray-blue-tint: #7a89a0;

  --ion-color-darker-blue: #304666;
	--ion-color-darker-blue-rgb: 48,70,102;
	--ion-color-darker-blue-contrast: #ffffff;
	--ion-color-darker-blue-contrast-rgb: 255,255,255;
	--ion-color-darker-blue-shade: #2a3e5a;
	--ion-color-darker-blue-tint: #455975;

  --ion-color-warning-red: #EC4B4B;
	--ion-color-warning-red-rgb: 236,75,75;
	--ion-color-warning-red-contrast: #ffffff;
	--ion-color-warning-red-contrast-rgb: 255,255,255;
	--ion-color-warning-red-shade: #d04242;
	--ion-color-warning-red-tint: #ee5d5d;

  --ion-color-invalid-red: #C01616;
	--ion-color-invalid-red-rgb: 192,22,22;
	--ion-color-invalid-red-contrast: #ffffff;
	--ion-color-invalid-red-contrast-rgb: 255,255,255;
	--ion-color-invalid-red-shade: #a91313;
	--ion-color-invalid-red-tint: #c62d2d;

  --ion-color-abyan-blue: #147AF7;
	--ion-color-abyan-blue-rgb: 20,122,247;
	--ion-color-abyan-blue-contrast: #ffffff;
	--ion-color-abyan-blue-contrast-rgb: 255,255,255;
	--ion-color-abyan-blue-shade: #126bd9;
	--ion-color-abyan-blue-tint: #2c87f8;

  --ion-color-abyan-light-blue: #0BC7F8;
	--ion-color-abyan-light-blue-rgb: 11,199,248;
	--ion-color-abyan-light-blue-contrast: #000000;
	--ion-color-abyan-light-blue-contrast-rgb: 0,0,0;
	--ion-color-abyan-light-blue-shade: #0aafda;
	--ion-color-abyan-light-blue-tint: #23cdf9;

  --ion-color-gray-blue: #C7D5E4;
	--ion-color-gray-blue-rgb: 199,213,228;
	--ion-color-gray-blue-contrast: #000000;
	--ion-color-gray-blue-contrast-rgb: 0,0,0;
	--ion-color-gray-blue-shade: #afbbc9;
	--ion-color-gray-blue-tint: #cdd9e7;

  --ion-color-abyan-black: #323B48;
	--ion-color-abyan-black-rgb: 50,59,72;
	--ion-color-abyan-black-contrast: #ffffff;
	--ion-color-abyan-black-contrast-rgb: 255,255,255;
	--ion-color-abyan-black-shade: #2c343f;
	--ion-color-abyan-black-tint: #474f5a;

  --ion-color-abyan-green: #3AA06F;
	--ion-color-abyan-green-rgb: 58,160,111;
	--ion-color-abyan-green-contrast: #ffffff;
	--ion-color-abyan-green-contrast-rgb: 255,255,255;
	--ion-color-abyan-green-shade: #338d62;
	--ion-color-abyan-green-tint: #4eaa7d;

  --ion-color-sand-gray: #404756;
	--ion-color-sand-gray-rgb: 64,71,86;
	--ion-color-sand-gray-contrast: #ffffff;
	--ion-color-sand-gray-contrast-rgb: 255,255,255;
	--ion-color-sand-gray-shade: #383e4c;
	--ion-color-sand-gray-tint: #535967;

  --ion-color-abyan-gray: #A7B1C6;
	--ion-color-abyan-gray-rgb: 167,177,198;
	--ion-color-abyan-gray-contrast: #000000;
	--ion-color-abyan-gray-contrast-rgb: 0,0,0;
	--ion-color-abyan-gray-shade: #939cae;
	--ion-color-abyan-gray-tint: #b0b9cc;
}

.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-instagram {
  --ion-color-base: var(--ion-color-instagram);
  --ion-color-base-rgb: var(--ion-color-instagram-rgb);
  --ion-color-contrast: var(--ion-color-instagram-contrast);
  --ion-color-contrast-rgb: var(--ion-color-instagram-contrast-rgb);
  --ion-color-shade: var(--ion-color-instagram-shade);
  --ion-color-tint: var(--ion-color-instagram-tint);
}

.ion-color-vimeo {
  --ion-color-base: var(--ion-color-vimeo);
  --ion-color-base-rgb: var(--ion-color-vimeo-rgb);
  --ion-color-contrast: var(--ion-color-vimeo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vimeo-contrast-rgb);
  --ion-color-shade: var(--ion-color-vimeo-shade);
  --ion-color-tint: var(--ion-color-vimeo-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-github {
  --ion-color-base: #211F1F;
  --ion-color-base-rgb: 33,31,31;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #1d1b1b;
  --ion-color-tint: #373535;
}

.ion-color-instagram {
  --ion-color-base: #9537BC;
  --ion-color-base-rgb: 149,55,188;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #8330a5;
  --ion-color-tint: #a04bc3;
}

.ion-color-darkblue {
  --ion-color-base: var(--ion-color-darkblue);
  --ion-color-base-rgb: var(--ion-color-darkblue-rgb);
  --ion-color-contrast: var(--ion-color-darkblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkblue-shade);
  --ion-color-tint: var(--ion-color-darkblue-tint);
}

.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-deep-blue {
	--ion-color-base: var(--ion-color-deep-blue);
	--ion-color-base-rgb: var(--ion-color-deep-blue-rgb);
	--ion-color-contrast: var(--ion-color-deep-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-deep-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-deep-blue-shade);
	--ion-color-tint: var(--ion-color-deep-blue-tint);
}

.ion-color-light-blue {
	--ion-color-base: var(--ion-color-light-blue);
	--ion-color-base-rgb: var(--ion-color-light-blue-rgb);
	--ion-color-contrast: var(--ion-color-light-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-light-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-light-blue-shade);
	--ion-color-tint: var(--ion-color-light-blue-tint);
}

.ion-color-light-gray {
	--ion-color-base: var(--ion-color-light-gray);
	--ion-color-base-rgb: var(--ion-color-light-gray-rgb);
	--ion-color-contrast: var(--ion-color-light-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-light-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-light-gray-shade);
	--ion-color-tint: var(--ion-color-light-gray-tint);
}

.ion-color-abyan-gray {
	--ion-color-base: var(--ion-color-abyan-gray);
	--ion-color-base-rgb: var(--ion-color-abyan-gray-rgb);
	--ion-color-contrast: var(--ion-color-abyan-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-abyan-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-abyan-gray-shade);
	--ion-color-tint: var(--ion-color-abyan-gray-tint);
}

.ion-color-abyan-light-blue {
	--ion-color-base: var(--ion-color-abyan-light-blue);
	--ion-color-base-rgb: var(--ion-color-abyan-light-blue-rgb);
	--ion-color-contrast: var(--ion-color-abyan-light-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-abyan-light-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-abyan-light-blue-shade);
	--ion-color-tint: var(--ion-color-abyan-light-blue-tint);
}

.ion-color-dark-blue {
	--ion-color-base: var(--ion-color-dark-blue);
	--ion-color-base-rgb: var(--ion-color-dark-blue-rgb);
	--ion-color-contrast: var(--ion-color-dark-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dark-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-dark-blue-shade);
	--ion-color-tint: var(--ion-color-dark-blue-tint);
}

.ion-color-merchant-green {
	--ion-color-base: var(--ion-color-merchant-green);
	--ion-color-base-rgb: var(--ion-color-merchant-green-rgb);
	--ion-color-contrast: var(--ion-color-merchant-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-merchant-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-merchant-green-shade);
	--ion-color-tint: var(--ion-color-merchant-green-tint);
}

.ion-color-header-blue {
	--ion-color-base: var(--ion-color-header-blue);
	--ion-color-base-rgb: var(--ion-color-header-blue-rgb);
	--ion-color-contrast: var(--ion-color-header-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-header-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-header-blue-shade);
	--ion-color-tint: var(--ion-color-header-blue-tint);
}

.ion-color-ash-gray {
	--ion-color-base: var(--ion-color-ash-gray);
	--ion-color-base-rgb: var(--ion-color-ash-gray-rgb);
	--ion-color-contrast: var(--ion-color-ash-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-ash-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-ash-gray-shade);
	--ion-color-tint: var(--ion-color-ash-gray-tint);
}

.ion-color-yellow-green {
	--ion-color-base: var(--ion-color-yellow-green);
	--ion-color-base-rgb: var(--ion-color-yellow-green-rgb);
	--ion-color-contrast: var(--ion-color-yellow-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-yellow-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-yellow-green-shade);
	--ion-color-tint: var(--ion-color-yellow-green-tint);
}

.ion-color-gradient {
	--ion-color-base: var(--ion-color-gradient);
	--ion-color-base-rgb: var(--ion-color-gradient-rgb);
	--ion-color-contrast: var(--ion-color-gradient-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gradient-contrast-rgb);
	--ion-color-shade: var(--ion-color-gradient-shade);
	--ion-color-tint: var(--ion-color-gradient-tint);
}

.ion-color-merchant-green {
	--ion-color-base: var(--ion-color-merchant-green);
	--ion-color-base-rgb: var(--ion-color-merchant-green-rgb);
	--ion-color-contrast: var(--ion-color-merchant-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-merchant-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-merchant-green-shade);
	--ion-color-tint: var(--ion-color-merchant-green-tint);
}

.ion-color-heavy-blue {
	--ion-color-base: var(--ion-color-heavy-blue);
	--ion-color-base-rgb: var(--ion-color-heavy-blue-rgb);
	--ion-color-contrast: var(--ion-color-heavy-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-heavy-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-heavy-blue-shade);
	--ion-color-tint: var(--ion-color-heavy-blue-tint);
}

.ion-color-gray-blue {
	--ion-color-base: var(--ion-color-gray-blue);
	--ion-color-base-rgb: var(--ion-color-gray-blue-rgb);
	--ion-color-contrast: var(--ion-color-gray-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-blue-shade);
	--ion-color-tint: var(--ion-color-gray-blue-tint);
}

.ion-color-darker-blue {
	--ion-color-base: var(--ion-color-darker-blue);
	--ion-color-base-rgb: var(--ion-color-darker-blue-rgb);
	--ion-color-contrast: var(--ion-color-darker-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-darker-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-darker-blue-shade);
	--ion-color-tint: var(--ion-color-darker-blue-tint);
}

.ion-color-warning-red {
	--ion-color-base: var(--ion-color-warning-red);
	--ion-color-base-rgb: var(--ion-color-warning-red-rgb);
	--ion-color-contrast: var(--ion-color-warning-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-warning-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-warning-red-shade);
	--ion-color-tint: var(--ion-color-warning-red-tint);
}

.ion-color-invalid-red {
	--ion-color-base: var(--ion-color-invalid-red);
	--ion-color-base-rgb: var(--ion-color-invalid-red-rgb);
	--ion-color-contrast: var(--ion-color-invalid-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-invalid-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-invalid-red-shade);
	--ion-color-tint: var(--ion-color-invalid-red-tint);
}

.ion-color-abyan-blue {
	--ion-color-base: var(--ion-color-abyan-blue);
	--ion-color-base-rgb: var(--ion-color-abyan-blue-rgb);
	--ion-color-contrast: var(--ion-color-abyan-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-abyan-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-abyan-blue-shade);
	--ion-color-tint: var(--ion-color-abyan-blue-tint);
}

.ion-color-gray-blue {
	--ion-color-base: var(--ion-color-gray-blue);
	--ion-color-base-rgb: var(--ion-color-gray-blue-rgb);
	--ion-color-contrast: var(--ion-color-gray-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-gray-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-gray-blue-shade);
	--ion-color-tint: var(--ion-color-gray-blue-tint);
}

.ion-color-abyan-black {
	--ion-color-base: var(--ion-color-abyan-black);
	--ion-color-base-rgb: var(--ion-color-abyan-black-rgb);
	--ion-color-contrast: var(--ion-color-abyan-black-contrast);
	--ion-color-contrast-rgb: var(--ion-color-abyan-black-contrast-rgb);
	--ion-color-shade: var(--ion-color-abyan-black-shade);
	--ion-color-tint: var(--ion-color-abyan-black-tint);
}

.ion-color-abyan-green {
	--ion-color-base: var(--ion-color-abyan-green);
	--ion-color-base-rgb: var(--ion-color-abyan-green-rgb);
	--ion-color-contrast: var(--ion-color-abyan-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-abyan-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-abyan-green-shade);
	--ion-color-tint: var(--ion-color-abyan-green-tint);
}

.ion-color-sand-gray {
	--ion-color-base: var(--ion-color-sand-gray);
	--ion-color-base-rgb: var(--ion-color-sand-gray-rgb);
	--ion-color-contrast: var(--ion-color-sand-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-sand-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-sand-gray-shade);
	--ion-color-tint: var(--ion-color-sand-gray-tint);
}

.ion-color-abyan-gray {
	--ion-color-base: var(--ion-color-abyan-gray);
	--ion-color-base-rgb: var(--ion-color-abyan-gray-rgb);
	--ion-color-contrast: var(--ion-color-abyan-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-abyan-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-abyan-gray-shade);
	--ion-color-tint: var(--ion-color-abyan-gray-tint);
}
/*
 * Shared Variables
 * ----------------------------------------------------------------------------
 * To customize the look and feel of this app, you can override
 * the CSS variables found in Ionic's source files.
 * To view all of the possible Ionic variables, see:
 * https://ionicframework.com/docs/theming/css-variables#ionic-variables
 */

:root {
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}

/*
 * App iOS Variables
 * ----------------------------------------------------------------------------
 * iOS only CSS variables can go here
 */

.ios {

}

/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

.md {

}

/*
 * App Theme
 * ----------------------------------------------------------------------------
 * Ionic apps can have different themes applied, which can
 * then be further customized. These variables come last
 * so that the above variables are used by default.
 */

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66,140,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80,200,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.ios {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0,0,0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;
  --ion-toolbar-text: #faf9f9;

  --ion-item-background: #000000;
}


/*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.md {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #0d0d0d;
  --ion-toolbar-text: #faf9f9;

  --ion-tab-bar-background: #1f1f1f;
}

@font-face {
  font-family: 'orbitron';
  src: url('../assets/fonts/Orbitron-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'opensans-italic';
  src: url('../assets/fonts/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'opensans-light';
  src: url('../assets/fonts/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'opensans-lightitalic';
  src: url('../assets/fonts/OpenSans-LightItalic.ttf');
}

@font-face {
  font-family: 'opensans-bold';
  src: url('../assets/fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'opensans-bolditalic';
  src: url('../assets/fonts/OpenSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'opensans-extrabold';
  src: url('../assets/fonts/OpenSans-ExtraBold.ttf');
}

@font-face {
  font-family: 'opensans-extrabolditalic';
  src: url('../assets/fonts/OpenSans-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'opensans-semibold';
  src: url('../assets/fonts/OpenSans-Semibold.ttf');
}

@font-face {
  font-family: 'opensans-semibolditalic';
  src: url('../assets/fonts/OpenSans-SemiboldItalic.ttf');
}

@font-face {
	font-family: 'Lexend';
	src: url('../assets/fonts/Lexend/Lexend-Regular.ttf');
}
