@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?hs4ej1');
  src:  url('fonts/icomoon.eot?hs4ej1#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?hs4ej1') format('truetype'),
    url('fonts/icomoon.woff?hs4ej1') format('woff'),
    url('fonts/icomoon.svg?hs4ej1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ads:before {
  content: "\e900";
}
.icon-become-a-community:before {
  content: "\e901";
}
.icon-becomre-a-store:before {
  content: "\e902";
}
.icon-bills-payment:before {
  content: "\e903";
}
.icon-bills-payment-locked:before {
  content: "\e904";
}
.icon-bills-payment-old:before {
  content: "\e905";
}
.icon-btc .path1:before {
  content: "\e906";
  color: rgb(86, 86, 86);
}
.icon-btc .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-btc-colored .path1:before {
  content: "\e908";
  color: rgb(247, 147, 26);
}
.icon-btc-colored .path2:before {
  content: "\e909";
  margin-left: -0.9697265625em;
  color: rgb(255, 255, 255);
}
.icon-busd .path1:before {
  content: "\e90a";
  color: rgb(86, 86, 86);
}
.icon-busd .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-busd .path3:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-busd .path4:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-busd .path5:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-busd .path6:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(86, 86, 86);
}
.icon-busd-colored .path1:before {
  content: "\e910";
  color: rgb(240, 185, 11);
}
.icon-busd-colored .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-busd-colored .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-busd-colored .path4:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-busd-colored .path5:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-busd-colored .path6:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cash-in:before {
  content: "\e916";
}
.icon-cash-in-old:before {
  content: "\e917";
}
.icon-cash-out:before {
  content: "\e918";
}
.icon-cash-out-old:before {
  content: "\e919";
}
.icon-community-members:before {
  content: "\e91a";
}
.icon-convert:before {
  content: "\e91b";
}
.icon-credit-limit:before {
  content: "\e91c";
}
.icon-delete-announce:before {
  content: "\e91d";
}
.icon-deleted:before {
  content: "\e91e";
}
.icon-download:before {
  content: "\e91f";
}
.icon-download-qr:before {
  content: "\e920";
}
.icon-edit-announce:before {
  content: "\e921";
}
.icon-edited:before {
  content: "\e922";
}
.icon-eload:before {
  content: "\e923";
}
.icon-eload-locked:before {
  content: "\e924";
}
.icon-eload-old:before {
  content: "\e925";
}
.icon-eth .path1:before {
  content: "\e926";
  color: rgb(86, 86, 86);
}
.icon-eth .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.6020;
}
.icon-eth .path3:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-eth .path4:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.6020;
}
.icon-eth .path5:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-eth .path6:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.2;
}
.icon-eth .path7:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.6020;
}
.icon-eth-colored .path1:before {
  content: "\e92d";
  color: rgb(98, 126, 234);
}
.icon-eth-colored .path2:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.6020;
}
.icon-eth-colored .path3:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-eth-colored .path4:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.6020;
}
.icon-eth-colored .path5:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-eth-colored .path6:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.2;
}
.icon-eth-colored .path7:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.6020;
}
.icon-eye-closed:before {
  content: "\e934";
}
.icon-eye-open:before {
  content: "\e935";
}
.icon-help-center:before {
  content: "\e936";
}
.icon-home:before {
  content: "\e937";
}
.icon-link-account:before {
  content: "\e938";
}
.icon-log-out:before {
  content: "\e939";
}
.icon-move:before {
  content: "\e93a";
}
.icon-my-link-accounts:before {
  content: "\e93b";
}
.icon-my-wallet:before {
  content: "\e93c";
}
.icon-nekoin-logo:before {
  content: "\e93d";
}
.icon-NFT:before {
  content: "\e93e";
}
.icon-nft-soon:before {
  content: "\e93f";
}
.icon-options-menu:before {
  content: "\e940";
}
.icon-OTC:before {
  content: "\e941";
}
.icon-others:before {
  content: "\e942";
}
.icon-p2p:before {
  content: "\e943";
}
.icon-peso-sign:before {
  content: "\e944";
}
.icon-post:before {
  content: "\e945";
}
.icon-qr-code:before {
  content: "\e946";
}
.icon-quick-guide:before {
  content: "\e947";
}
.icon-received:before {
  content: "\e948";
}
.icon-referral-bonus:before {
  content: "\e949";
}
.icon-scan-qr:before {
  content: "\e94a";
}
.icon-s-credits:before {
  content: "\e94b";
}
.icon-s-credits-locked:before {
  content: "\e94c";
}
.icon-s-credits-old:before {
  content: "\e94d";
}
.icon-sdx:before {
  content: "\e94e";
}
.icon-Send-Credit:before {
  content: "\e94f";
}
.icon-send:before {
  content: "\e950";
}
.icon-send-credit:before {
  content: "\e951";
}
.icon-send-credit-old:before {
  content: "\e952";
}
.icon-send-email:before {
  content: "\e953";
}
.icon-send-qr:before {
  content: "\e954";
}
.icon-send-qr-colored .path1:before {
  content: "\e955";
  color: rgb(196, 196, 196);
}
.icon-send-qr-colored .path2:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(86, 86, 86);
}
.icon-send-qr-colored .path3:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(0, 82, 183);
}
.icon-send-qr-colored .path4:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(13, 123, 99);
}
.icon-send-sms:before {
  content: "\e959";
}
.icon-setting:before {
  content: "\e95a";
}
.icon-share:before {
  content: "\e95b";
}
.icon-Store:before {
  content: "\e95c";
}
.icon-swap:before {
  content: "\e95d";
}
.icon-token:before {
  content: "\e95e";
}
.icon-transaction-history:before {
  content: "\e95f";
}
.icon-usdc .path1:before {
  content: "\e960";
  color: rgb(86, 86, 86);
}
.icon-usdc .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-usdc .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-usdc-colored .path1:before {
  content: "\e963";
  color: rgb(39, 117, 202);
}
.icon-usdc-colored .path2:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-usdc-colored .path3:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-usdt .path1:before {
  content: "\e966";
  color: rgb(86, 86, 86);
}
.icon-usdt .path2:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-usdt-colored .path1:before {
  content: "\e968";
  color: rgb(38, 161, 123);
}
.icon-usdt-colored .path2:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-withdraw:before {
  content: "\e96a";
}
